/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { Trans, useTranslation } from "@trueskin-web/translations"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("404.seoTitle")} />
      <h1>
        <Trans i18nKey="404.title" />
      </h1>
      <p>
        <Trans i18nKey="404.description" />
      </p>
    </Layout>
  )
}

export default NotFoundPage
